import React from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex } from '../styles/index'
import paper from '../../static/paperBackground/paper.jpeg'
import LinkTool from '../components/shared/LinkTool'
import {useGlobalLayoutQuery} from '../hooks/index'
import Img from 'gatsby-image'
import { useSpring, animated, config } from 'react-spring'

export default function NotFoundPage() {
  const layout = useGlobalLayoutQuery()

  const [props, set] = useSpring(() => ({
     xy: [0, 0], 
     config: {
      tension: 280,
      friction: 80
    }
    }))

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
  const trans1 = (x, y) => `translate3d(${x / 25}px,${y / 25}px,0)`
  const trans2 = (x, y) => `translate3d(${x / 37.5}px,${y / 37.5}px,0)`
  const trans3 = (x, y) => `translate3d(${x / 62.5}px,${y / 62.5}px,0)`
  const trans4 = (x, y) => `translate3d(${x / 50}px,${y / 50}px,0)`

  const browser = typeof window !== 'undefined' && window

  return (
    browser && (
      <Wrapper
        onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      >
        <TextWrapper style={{ transform: props.xy.interpolate(trans4) }}>
          <Title>404</Title>
          <LinkWrapper>
            <LinkTool to={`/`}>Go back to homepage</LinkTool>
          </LinkWrapper>
        </TextWrapper>

        <ToprightImg style={{ transform: props.xy.interpolate(trans1) }}>
          <Img fluid={layout?.notFoundPageImages[0].fluid} />
        </ToprightImg>
        <BottomLeftImg style={{ transform: props.xy.interpolate(trans2) }}>
          <Img fluid={layout?.notFoundPageImages[1].fluid} />
        </BottomLeftImg>
        <BottomRightImg style={{ transform: props.xy.interpolate(trans3) }}>
          <Img fluid={layout?.notFoundPageImages[2].fluid} />
        </BottomRightImg>
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${paper});
  background-repeat: repeat;
  overflow: hidden;
  ${desktopBreakpoint}{
    padding-bottom: ${desktopVW(90)}; 
  }
`;

const Title = styled.div`
  font-family: 'messina';
  font-weight: 300;
  font-size: ${mobileVW(120)};
  ${desktopBreakpoint}{
    font-size: ${desktopVW(310)};
  }
`;

const LinkWrapper = styled.div`
  font-family: 'messinaMono';
  color: ${colors.subtleGrey};
  letter-spacing: -0.05em;
  will-change: transform;
  ${desktopBreakpoint}{
    margin-top: ${desktopVW(-45)};
    font-size: ${desktopVW(14)};
    border-bottom: ${desktopVW(1)} solid ${colors.subtleGrey};
  }
`;

const TextWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ToprightImg = styled(animated.div)`
  position: absolute;
  z-index: ${zIndex.surface};

  ${desktopBreakpoint}{
    top: ${desktopVW(115)};
    right: ${desktopVW(120)};
    width: ${desktopVW(240)};
  }
`;

const BottomLeftImg = styled(animated.div)`
  position: absolute;
  z-index: ${zIndex.surface};

  ${desktopBreakpoint}{
    bottom: ${desktopVW(-155)};
    left: ${desktopVW(-20)};
    width: ${desktopVW(490)};
  }
`;

const BottomRightImg = styled(animated.div)`
  position: absolute;
  z-index: ${zIndex.surface};

  ${desktopBreakpoint}{
    bottom: ${desktopVW(-240)};
    right: ${desktopVW(120)};
    width: ${desktopVW(540)};
  }
`;
